import React, {MouseEventHandler, useCallback} from 'react';
import {FileEntity} from '../../pages/FilesPage';
import {FilesService} from '../../services/Files/FilesService';
import {ButtonBase, Stack, Typography} from '@mui/material';
import {downloadFile} from 'utils/api/file';
import {DT_FORMATS, formatDate} from 'utils';

type FileCellProps = {
    record: FileEntity;
};

export const downloadFieldName = 'Скачать файл';
const service = FilesService.getInstance();

export const FileCell = ({record}: FileCellProps) => {
    const linkClickHandle: MouseEventHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const link = record._links[downloadFieldName].href.replace(/.*api/, '');
            const data = await service.downloadFileFromTable(link);

            downloadFile(data.data, record.originalFilename);
        },
        [record],
    );

    return (
        <ButtonBase
            onClick={linkClickHandle}
            sx={{
                width: '100%',
                textAlign: 'left',
                padding: 1,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
                display: 'flex',
                '&:hover': {
                    backgroundColor: (theme) => theme.palette.action.hover,
                    borderRadius: '4px',
                },
            }}
        >
            <Stack
                direction='column'
                width='100%'
            >
                <Typography
                    variant='button'
                    color='inherit'
                    sx={{justifyContent: 'flex-start'}}
                >
                    {record.originalFilename}
                </Typography>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width='60%'
                >
                    {record.documentDate && (
                        <Stack direction='row'>
                            <Typography
                                variant='caption'
                                sx={{opacity: '.56', paddingRight: 1}}
                            >
                                от
                            </Typography>
                            <Typography variant='caption'>
                                {`${formatDate(record.documentDate, DT_FORMATS.defaultDate)}`}
                            </Typography>
                        </Stack>
                    )}

                    <Typography
                        variant='caption'
                        sx={{opacity: '.56'}}
                    >
                        {`Загрузка ${formatDate(
                            record.uploadDate,
                            DT_FORMATS.defaultDate,
                        )} в ${formatDate(record.uploadDate, DT_FORMATS.shortTime)}`}
                    </Typography>

                    <Typography
                        variant='caption'
                        sx={{opacity: '.56'}}
                    >
                        {`${Math.round(record.fileSize * 0.001)} kb`}
                    </Typography>
                </Stack>
            </Stack>
        </ButtonBase>
    );
};
