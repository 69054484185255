import {EChartOption} from 'echarts';

export const trajectoryHorizontalSliceConfiuration = (): EChartOption => {
    return {
        xAxis: {},
        yAxis: {},
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: [0],
            },
            {
                type: 'inside',
                yAxisIndex: [0],
            },
        ],
    };
};
