import {IconButton, LinearProgress, Stack, Typography} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteFilledIcon from '@mui/icons-material/Delete';
import {FileWithProgress} from './types';

const formatSizeText = (size: number): string => {
    if (size > 1024 * 1024) {
        return `${Math.floor(size / 1024 / 1024)}Mb`;
    }
    if (size > 1024) {
        return `${Math.floor(size / 1024)}Kb`;
    }
    return `${size}b`;
};

type UploadFileCardProps = {
    file: FileWithProgress;
    onDelete: () => void;
    forceDelete?: boolean;
    width?: string;
};
export const UploadFileCard: React.FC<UploadFileCardProps> = ({
    file,
    onDelete,
    forceDelete = false,
    width = 'calc(50% - 10px)',
}) => {
    return (
        <div style={{width, padding: '12px 16px'}}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                    flexGrow={1}
                >
                    <UploadFileIcon color={file.error ? 'error' : 'primary'} />
                    <Stack
                        direction='column'
                        flexGrow={1}
                    >
                        <Typography
                            variant='regular1'
                            color={
                                file.error
                                    ? 'var(--mui-palette-error-main)'
                                    : 'var(--mui-palette-text-primary)'
                            }
                        >
                            {file.error ? `Загрузка не удалась '${file.name}'` : file.name}
                        </Typography>
                        <Typography
                            variant='regular2'
                            color={
                                file.error
                                    ? 'var(--mui-palette-error-main)'
                                    : 'var(--mui-palette-text-primary)'
                            }
                        >
                            {file.error ? file.error : formatSizeText(file.size)}&nbsp;•&nbsp;
                            {file.error ? 'Failed' : file.progress === 1 ? 'Загружено' : 'Загрузка'}
                        </Typography>
                        <LinearProgress
                            sx={{width: '25%', marginTop: '4px'}}
                            variant='determinate'
                            color={file.error ? 'error' : 'primary'}
                            value={(file.progress || 0) * 100}
                        />
                    </Stack>
                </Stack>
                {(forceDelete || file.progress !== 1) && (
                    <IconButton onClick={onDelete}>
                        <DeleteFilledIcon
                            color='action'
                            sx={{opacity: '.56'}}
                        />
                    </IconButton>
                )}
            </Stack>
        </div>
    );
};
