import {ChartCode, ChartItem, ReflectConfig} from '../types';
import {LineConfig} from '@ant-design/plots';
import {SupportedColorScheme} from '@mui/material';
import {computeAxisLineColor, computeFill, computeStrokeOpacity} from './common';
import {DT_FORMATS, formatDate} from 'utils';

const prepareReflectConfig = (reflect?: ReflectConfig): LineConfig['reflect'] => {
    switch (reflect) {
        case ReflectConfig.XY:
            return ['x', 'y'];
        case ReflectConfig.X:
            return 'x';
        case ReflectConfig.Y:
            return 'y';
        default:
            return;
    }
};

export const getLineConfig = (
    {code, settings}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
): Omit<LineConfig, 'data'> => {
    const isTime = [ChartCode.COST_BY_DAY, ChartCode.GEOLOGICAL_JOURNAL].some((v) => code === v);
    const config: Omit<LineConfig, 'data'> = {
        xField: 'x',
        yField: 'y',
        seriesField: 'type',
        meta: {
            x: {
                type: isTime ? 'time' : 'linear',
            },
        },
        tooltip: {
            title: (title: string, datum: any) => {
                if (isTime) {
                    if (code === ChartCode.COST_BY_DAY) {
                        return formatDate(datum.x, DT_FORMATS.defaultDate);
                    } else {
                        return formatDate(datum.x, DT_FORMATS.short);
                    }
                }
                return datum.x as string;
            },
            formatter: (item: any) => {
                return {name: item.type as string, value: item.y as string};
            },
        },
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
            maxItemWidth: 300,
        },
        interactions: [
            {
                type: 'brush',
            },
        ],
        reflect: prepareReflectConfig(settings?.reflect),
        yAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    if (code === ChartCode.COST_BY_DAY) {
        // @ts-ignore
        config.yAxis = {
            title: {
                text: '',
                position: 'center',
            },
            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        fill: computeAxisLineColor(colorScheme),
                    },
                },
            },
        };

        // @ts-ignore
        config.interactions = [
            {type: 'chart:zoom', enable: false},
            {type: 'chart:pan', enable: false},
        ];
        // @ts-ignore
        config.color = ({type}: {type: string}) => {
            if (type.includes('Факт')) {
                return '#009688';
            }

            return '#2963C5';
        };

        // @ts-ignore
        config.xAxis = {
            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        stroke: computeAxisLineColor(colorScheme),
                    },
                },
            },
            label: {
                formatter: (text: string) => {
                    return formatDate(text, 'DD.MM.YYYY');
                },
            },
        };

        // @ts-ignore
        config.area = {
            style: (d: {type: string}) => {
                const {type} = d;

                if (type.includes('Факт')) {
                    return {
                        fill: 'l(90) 1:#00968800 0.65:#00968875 0:#00968875',
                        shadowColor: '#00968875',
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowOffsetY: 5,
                    };
                }
                if (type.includes('План')) {
                    return {
                        fill: 'l(90) 0:#00968800 0.5:#00968800 1:#00968800',
                        fillOpasity: 0,
                    };
                }
            },
        };
    }

    if (code === ChartCode.GEOLOGICAL_JOURNAL) {
        //@ts-ignore
        config.interactions = [];
    }

    return config;
};
