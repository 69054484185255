import {useMemo, useCallback} from 'react';
import {ChartFiltersProps} from './types';
import {Box} from '@mui/material';

import styles from './Chart.module.scss';
import ChartFilter from './ChartFilter';

const ChartFilters = ({chart, setFilters, filters}: ChartFiltersProps) => {
    const filteredChart = useMemo(() => {
        // параметр wellId приходит автоматически у всех элементов, но отображаться не должен
        return chart.params.filter((el) => el.code !== 'wellId');
    }, [chart.params]);

    const onFilterChange = useCallback(
        (filterCode: string, newFilterValue: string[]) => {
            if (filters[filterCode] !== newFilterValue) {
                setFilters((_prev) => ({..._prev, [filterCode]: newFilterValue}));
            }
        },
        [filters, setFilters],
    );

    if (!filteredChart.length) {
        return null;
    }

    return (
        <Box
            className={styles.filtersBox}
            component='div'
        >
            {filteredChart.map((el) => (
                <div
                    key={el.code}
                    className={styles.chartFilter}
                >
                    <ChartFilter
                        filter={el}
                        onFilterChange={onFilterChange}
                    />
                </div>
            ))}
        </Box>
    );
};

export default ChartFilters;
