// @ts-nocheck
import {EChartOption} from 'echarts';

// Эта функцию позволит использовать и свойства из дефолтной конфигурации и свойства из конфигурации графика (при этом свойства графика будут приоритетнее)
export const mergeObjects = (obj1: EChartOption, obj2: EChartOption) => {
    const result = {...obj2}; // Копируем второй объект, чтобы сохранить его значения

    Object.keys(obj1).forEach((key) => {
        if (obj1[key] !== null && typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
            // Если ключ - это объект, выполняем рекурсивное слияние
            result[key] = mergeObjects(obj1[key], result[key] || {});
        } else {
            // Если ключ - примитивное значение, просто берем значение из первого объекта
            result[key] = obj1[key];
        }
    });

    return result;
};
