import {EChartOption} from 'echarts';

const color = 'rgba(255, 255, 255, 0.7)';

export const customChartsDarkTheme: EChartOption = {
    backgroundColor: '#2A2A2A',
    textStyle: {
        color,
    },
    tooltip: {
        backgroundColor: '#363636',
        textStyle: {color},
    },
    legend: {
        textStyle: {
            color,
        },
        pageTextStyle: {
            color: 'rgba(255, 255, 255, 0.38)',
        },
        inactiveColor: 'rgba(255, 255, 255, 0.38)',
        pageIconColor: 'rgba(255, 255, 255, 0.56)',
        pageIconInactiveColor: 'rgba(255, 255, 255, 0.12)',
    },
    // @ts-ignore
    valueAxis: {
        splitLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.12)',
            },
        },
    },
    axisLabel: {
        color: 'rgba(255, 255, 255, 0.56)',
    },
};
