import {
    BarChartProps,
    ChartItem,
    ChartProps,
    ChartType,
    ColumnChartProps,
    PieChartProps,
    RangeBarProps,
    WaterfallChartProps,
} from '../types';
import {Bar, Column, Line, Pie, Waterfall} from '@ant-design/plots';

import {G2, LineOptions as G2plotConfig} from '@antv/g2plot';
import {SupportedColorScheme} from '@mui/material';
import {DeviceInfo} from 'utils/hooks/useDeviceInfo';
import {getBarConfig} from './barConfig';
import {getRangeBarConfig} from './rangeBarConfig';
import {getColumnConfig} from './columnConfig';
import {getPieConfig} from './pieConfig';
import {getWaterfallConfig} from './waterfallConfig';
import {getLineConfig} from './lineConfig';

const combiningConfigs = (
    defConfig: Record<string, any>,
    config: Record<string, any>,
): Record<string, any> => {
    const result = {...defConfig};

    for (const key in config) {
        if (key in result) {
            if (typeof result[key] === 'object' && typeof config[key] === 'object') {
                result[key] = combiningConfigs(result[key], config[key]);
            } else {
                result[key] = config[key];
            }
        } else {
            result[key] = config[key];
        }
    }

    return result;
};

export const getChartByType = (type: ChartType) => {
    switch (type) {
        case ChartType.Bar:
        case ChartType.RangeBar:
            return Bar;
        case ChartType.Column:
            return Column;
        case ChartType.Waterfall:
            return Waterfall;
        case ChartType.Pie:
            return Pie;
        case ChartType.Line:
        default:
            return Line;
    }
};

export const getChartsConfig = (
    chart: ChartItem,
    values: ChartProps,
    colorScheme: SupportedColorScheme | undefined,
    customConfig?: (
        config: Omit<G2plotConfig, 'tooltip' | 'data'>,
        values?: ChartProps,
    ) => G2plotConfig,
    isFullscreen?: boolean,
    deviceInfo?: DeviceInfo,
    isShowLegend?: boolean,
) => {
    const theme = G2.getTheme(colorScheme);
    const defaultConfig = {
        tooltip: {
            domStyles: {
                ...theme.components.tooltip.domStyles,
                'g2-tooltip-marker': {
                    width: '20px',
                    height: '20px',
                },
                'g2-tooltip-list-item': {
                    display: 'flex',
                    alignItems: 'center',
                },
                'g2-tooltip-title': {
                    fontSize: '14px',
                },
            },
        },
    };

    let config;

    switch (chart.type) {
        case ChartType.Bar:
            config = getBarConfig(chart, colorScheme, values as BarChartProps);
            break;
        case ChartType.RangeBar:
            config = getRangeBarConfig(values as RangeBarProps, colorScheme);
            break;
        case ChartType.Column:
            config = getColumnConfig(
                chart,
                colorScheme,
                values as ColumnChartProps,
                deviceInfo,
                isFullscreen,
            );
            break;
        case ChartType.Pie:
            config = getPieConfig(
                chart,
                colorScheme,
                isFullscreen,
                values as PieChartProps,
                isShowLegend,
            );
            break;
        case ChartType.Waterfall:
            config = getWaterfallConfig(colorScheme, values as WaterfallChartProps);
            break;
        case ChartType.Line:
        default:
            config = getLineConfig(chart, colorScheme);
            break;
    }
    config = combiningConfigs(defaultConfig, config);

    return customConfig ? customConfig(config, values) : config;
};
