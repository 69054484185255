import {NpvDataDisplayFormat} from '@features/npvAnalytics/types';
import {Theme} from '@mui/material';
import dayjs from 'dayjs';
import {EChartOption} from 'echarts';

export const npvAnalyticsConfiuration = (theme: Theme): EChartOption => {
    return {
        grid: {
            bottom: 100,
            top: 40,
        },
        tooltip: {
            trigger: 'axis',
            triggerOn: 'mousemove',
            formatter: function (params) {
                if (!Array.isArray(params)) {
                    return '';
                }
                const npvPercentObject = params.find((item) => item.seriesType === 'line');
                const displayFormat = npvPercentObject?.data.displayFormat;
                const npvDays = params.filter((item) => item.seriesType === 'bar');
                // Получаем дату окончания бурения из данных
                const date = npvPercentObject?.data?.date;
                const year = dayjs(date).format('YYYY');

                const wellTitle = npvPercentObject?.data.wellTitle;
                const monthTitle = dayjs(date).format('MMMM');

                const notEmptyData = npvDays.filter((item) => !!item.value);

                // Формируем HTML разметку для тултипа
                return `
                    <div style="display: flex; flex-direction: column; gap: 5px; max-width: 200px; color: ${theme.palette.text.secondary}; font-weight: 400;">
                        <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 30px;">
                            <div style="font-weight: 500; font-size: 12px; color: ${theme.palette.text.primary}">
                                ${displayFormat === NpvDataDisplayFormat.BY_MONTH ? monthTitle : `Скважина ${wellTitle}`}
                            </div>
                            <div style="background: ${theme.palette.action.selected}; padding: 0 4px; border-radius: 4px; font-size: 10px;">
                                ${year}
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; width: 100%;">
                            <div style="display: flex; flex-direction: row; align-items: center; width: 100%">
                                ${npvPercentObject?.marker} Всего, % <div style="margin-left: auto">${npvPercentObject?.value}</div>%
                            </div>
                            ${
                                notEmptyData.length
                                    ? `<div style="display: flex; flex-direction: row; align-items: center; padding-left: 15px; border-radius: 4px; background-color: rgba(${theme.palette.text.primaryChannel} / 0.04); color: ${theme.palette.text.primary}">
                                            Всего, сут <div style="margin-left: auto">${notEmptyData[0].data.npvDays}</div>
                                        </div>`
                                    : ''
                            }
                            ${notEmptyData
                                .map(
                                    (item) =>
                                        `<div style="width: 100%; display: flex; flex-direction: row; align-items: center; gap: 6px; justify-content: space-between">
                                            <div class="ellipse-text">${item.marker} ${item.data.title}</div> <div>${item.value}</div>
                                        </div>`,
                                )
                                .join('')}
                        </div>
                       
                    </div>
                `;
            },
            axisPointer: {
                type: 'shadow',
            },
        },
        yAxis: [
            {
                type: 'value', // НПВ, сут
                name: 'сут',
                nameTextStyle: {
                    align: 'right',
                },
            },
            {
                type: 'value', // НПВ, %
                position: 'right',
                show: false,
            },
        ],
    };
};
