import {Theme} from '@mui/material';
import dayjs from 'dayjs';
import {EChartOption} from 'echarts';

export const introductoryConfiuration = (theme: Theme): EChartOption => {
    return {
        tooltip: {
            trigger: 'axis',
            triggerOn: 'mousemove',
            formatter: function (params) {
                if (!Array.isArray(params)) {
                    return '';
                }
                const barObject = params.find((item) => item.seriesName === 'Bar');
                const title = dayjs(`${barObject?.data.year}-${barObject?.data.month}-01`).format(
                    'MMMM YYYY',
                );

                const planObject = params.find(
                    (item) => item.seriesType === 'line' && item.seriesName === 'План',
                );
                const factObject = params.find(
                    (item) => item.seriesType === 'line' && item.seriesName === 'Факт',
                );

                // Формируем HTML разметку для тултипа
                return `
                    <div style="display: flex; flex-direction: column; gap: 5px; max-width: 200px; color: ${theme.palette.text.secondary}; font-weight: 400;">
                        <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 30px; align-items: center">
                            <div style="font-weight: 500; font-size: 12px; color: ${theme.palette.text.primary}">
                                ${title}
                            </div>
                            <div style="color: ${barObject?.data?.isNegative ? theme.palette.error.main : theme.palette.success.main} ; font-size: 16px; display: flex; flex-direction: row; align-items: center">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(${barObject?.data?.isNegative ? 0 : 180}deg); transition: transform 0.3s;">>
                                    <path d="M12.75 7.5L9 11.25L5.25 7.5L12.75 7.5Z" fill="currentColor"/>
                                </svg> ${barObject?.data.value}
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; width: 100%;">
                            <div style="width: 100%; display: flex; flex-direction: row; align-items: center; gap: 6px; justify-content: space-between">
                                <div>${planObject?.marker} ПЛАН</div> <div>${planObject?.value} скв</div>
                            </div>
                            <div style="width: 100%; display: flex; flex-direction: row; align-items: center; gap: 6px; justify-content: space-between">
                                <div>${factObject?.marker} ФАКТ</div> <div>${factObject?.value} скв</div>
                            </div>
                        </div>

                    </div>
                `;
            },
        },
        legend: {
            data: ['План', 'Факт'],
            left: 40,
            top: 0,
        },
        yAxis: {
            type: 'value',
        },
        backgroundColor: 'transparent',
    };
};
