import {Theme} from '@mui/material';
import {EChartOption} from 'echarts';

export const costExpenseStructureByCasingColumnConfiguration = (theme: Theme): EChartOption => {
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params: any) => {
                return params
                    .filter((item: number) => item !== 0)
                    .map(
                        (item: {seriesName: string; value: number}) =>
                            `${item.seriesName}: ${item.value}`,
                    )
                    .join('<br/>');
            },
        },
        legend: {
            bottom: 0,
            data: [],
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
            },
        ],
        yAxis: [
            {
                type: 'value',
                nameTextStyle: {
                    align: 'right',
                },
                splitNumber: 4,
                min: 0,
                boundaryGap: ['0%', '60%'],
                minInterval: 1,
                axisTick: {
                    alignWithLabel: true,
                },
            },
        ],
        series: [],
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: [0],
            },
            {
                type: 'inside',
                yAxisIndex: [0],
            },
        ],
    };
};
