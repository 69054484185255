
import api, {instance} from '../../utils/api';
import {FileEntity} from '../../pages/FilesPage';
import {AxiosProgressEvent, AxiosResponse} from 'axios';
import {ReportData, loadReportParams} from 'components/ReportModal/types';
import { PageData } from 'utils/hooks/useNewPageData';

export class FilesService {
    private static instance: FilesService;

    public static getInstance() {
        if (!FilesService.instance) {
            FilesService.instance = new FilesService();
        }

        return FilesService.instance;
    }

    private constructor() {}

    public getFiles(
        id: string,
        params: Record<string, string | number | undefined>,
    ): Promise<PageData<FileEntity>> {
        return api.get(`/files/group/${id}`, params);
    }

    public async createFile(
        data: FormData,
        params: Record<string, string | number | undefined>,
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
        signal?: AbortSignal,
    ): Promise<void> {
        await instance({
            method: 'POST',
            url: `/files/uploadWithGroupId`,
            data,
            params,
            onUploadProgress,
            signal,
            withoutGlobalLoader: true,
        });
    }

    public async deleteFile(id: string): Promise<void> {
        await api.delete(`/files/${id}`);
    }

    public getFile(
        type: string,
        params: loadReportParams,
        filters: Record<string, string | number | undefined>,
    ): Promise<AxiosResponse<string>> {
        const commentParam = params?.comment ? `&comment=${params.comment}` : '';

        return instance({
            method: 'POST',
            url: `/export/${type}?wellId=${params.wellId}${commentParam}`,
            data: filters,
            responseType: 'blob',
        });
    }

    public getReportFile(type: string, wellIds: string[]): Promise<AxiosResponse<ReportData>> {
        return instance({
            method: 'POST',
            url: `/export/${type}?wellIds=${wellIds.join(',')}`,
            data: {
                wellIds,
            },
        });
    }

    private getFileInfo(
        type: string,
        params: loadReportParams,
        filters: Record<string, string | number | undefined>,
    ): Promise<AxiosResponse<ReportData>> {
        const commentParam = params?.comment ? `&comment=${params.comment}` : '';
        const wellIdParam = params?.wellId ? `wellId=${params.wellId}` : '';
        return instance({
            method: 'POST',
            url: `/export/${type}?${wellIdParam}${commentParam}`,
            data: filters,
        });
    }

    public async getReportData(
        type: string,
        params: loadReportParams,
        filters: Record<string, any>,
    ) {
        const {data} = await this.getFileInfo(type, params, filters);
        return data;
    }

    public downloadFileFromTable(link: string): Promise<AxiosResponse<string>> {
        return instance.get(link, {
            responseType: 'blob',
            errorMessage: 'Не удалось скачать шаблон',
        });
    }

    public downloadFile(id: string) {
        return instance({
            method: 'GET',
            url: `/export/download?id=${id}`,
            responseType: 'blob',
        });
    }

    public async uploadStowageRegistersTemplate(
        url: string,
        data: FormData,
        params: Record<string, string | number | undefined>,
    ): Promise<void> {
        await instance({
            method: 'POST',
            url: `/${url}/import`,
            data,
            params,
        });
    }

    public generateReport(
        type: string,
        params: Record<string, string | number | undefined>,
    ): Promise<AxiosResponse<ReportData>> {
        return instance({
            method: 'POST',
            url: `/export/${type}`,
            params,
        });
    }
}
