import {SaveAltOutlined} from '@mui/icons-material';
import {IconButton} from '@mui/material';

type SaveAsImageButtonProps = {
    onClick: () => void;
};

export const SaveAsImageButton = ({onClick}: SaveAsImageButtonProps) => {
    return (
        <IconButton onClick={onClick}>
            <SaveAltOutlined />
        </IconButton>
    );
};
