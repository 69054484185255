import {EChartOption} from 'echarts';
import {ChartCode} from '../../Chart/types';
import {getCommonChartsConfig} from './getCommonChartsConfig';
import {mergeObjects} from './utils';
import {Theme} from '@mui/material';
import {trajectoryHorizontalSliceConfiuration} from './TRAJECTORY_HORIZONTAL_SLICE';
import {trajectoryVerticalSliceConfiuration} from './TRAJECTORY_VERTICAL_SLICE';
import {npvAnalyticsConfiuration} from './NPV_ANALYTICS';
import {introductoryConfiuration} from './INTRODUCTORY';
import {trajectory3DConfiguration} from './TRAJECTORY_3D';
import {costExpenseStructureByCasingColumnConfiguration} from './COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN';
import {costByDayConfiguration} from './COST_BY_DAY';
import {ggdFactConfiguration} from './GGD_FACT';

const chartConfigurations = {
    [ChartCode.TRAJECTORY_HORIZONTAL_SLICE]: trajectoryHorizontalSliceConfiuration,
    [ChartCode.TRAJECTORY_VERTICAL_SLICE]: trajectoryVerticalSliceConfiuration,
    [ChartCode.NPV_ANALYTICS]: npvAnalyticsConfiuration,
    [ChartCode.INTRODUCTORY]: introductoryConfiuration,
    [ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN]:
        costExpenseStructureByCasingColumnConfiguration,
    [ChartCode.TRAJECTORY_3D]: trajectory3DConfiguration,
    [ChartCode.COST_BY_DAY]: costByDayConfiguration,
    [ChartCode.GGD_FACT]: ggdFactConfiguration,
};

type getChartConfigurationProps = {
    code: ChartCode;
    theme?: Theme;
};

// Каждый раз при использовании NewChart мы вызываем эту функцию, чтобы сформировать конфигурацию на основании кода графика + дефолтная
export const getChartConfiguration = ({code, theme}: getChartConfigurationProps): EChartOption => {
    // @ts-ignore
    const func = chartConfigurations[code];
    if (func) {
        return mergeObjects(func(theme), getCommonChartsConfig());
    } else {
        return getCommonChartsConfig();
    }
};
