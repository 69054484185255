import useMediaQuery from '@mui/material/useMediaQuery';

export type DeviceInfo = Record<string, boolean>;

export const useDeviceInfo = (): DeviceInfo => {
    const isDesktop = useMediaQuery('(min-width: 1537px)');
    const isTablet = useMediaQuery('(min-width: 1440px) and (max-width: 1536px)');
    const isSmallTablet = useMediaQuery('(min-width: 960px) and (max-width: 1439px)');
    return {
        isDesktop,
        isTablet,
        isSmallTablet,
    };
};
