import {Box, Card, Paper, Stack} from '@mui/material';
import DeleteFilledIcon from '@mui/icons-material/Delete';
import {FileCell} from 'features/files/FileCell';
import {ButtonWithConfirm} from 'components/ButtonWithConfirm/ButtonWithConfirm';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {FileEntity} from 'pages/FilesPage';
import {useCallback} from 'react';

type FileCardProps = {
    file: FileEntity;
    onDelete?: (fileId: string) => Promise<void>;
    width?: string;
    isFirst?: boolean;
    isLast?: boolean;
    isOnlyOne?: boolean;
};

export const FileCard: React.FC<FileCardProps> = ({
    file,
    onDelete,
    width = '100%',
    isFirst,
    isLast,
    isOnlyOne,
}) => {
    const handleDelete = useCallback(() => {
        onDelete?.(file.id);
    }, [file.id, onDelete]);

    const borderRadius = isOnlyOne ? '4px' : isFirst ? '4px 4px 0 0' : isLast ? '0 0 4px 4px' : '0';

    return (
        <Card sx={{width, borderRadius, boxShadow: 'none'}}>
            <Paper
                elevation={0}
                sx={{
                    height: '100%',
                    padding: '12px 16px',
                    borderRadius: 'inherit',
                }}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                        width='100%'
                    >
                        <InsertDriveFileOutlinedIcon color='primary' />
                        <Box
                            component='div'
                            width='100%'
                        >
                            <FileCell record={file} />
                        </Box>
                    </Stack>
                    {Boolean(onDelete) && (
                        <ButtonWithConfirm
                            icon={
                                <DeleteFilledIcon
                                    color='action'
                                    sx={{opacity: '.56'}}
                                />
                            }
                            onConfirm={handleDelete}
                            title={`Удалить файл ${file.originalFilename}`}
                        />
                    )}
                </Stack>
            </Paper>
        </Card>
    );
};
