import {DictionaryItem} from 'utils';

export enum NpvAnalyticsStage {
    DRILLING = 'DRILLING',
    COMPLETION = 'COMPLETION',
}

export enum NpvAnalyticsDetalization {
    SERVICE_TYPE = 'SERVICE_TYPE',
    NPV_TYPE = 'NPV_TYPE',
}

export enum NpvAnalyticsIncidentWorkTypeGroup {
    DEFECT = 'DEFECT',
    GEOLOGY_COMPLICATIONS = 'GEOLOGY_COMPLICATIONS',
    IDLE = 'IDLE',
    METEO = 'METEO',
    DECISION_WAITING = 'DECISION_WAITING',
}

export type NpvAnalyticsFilters = {
    incidentWorkTypeGroup: NpvAnalyticsIncidentWorkTypeGroup[];
    serviceType: DictionaryItem[];
    casingColumn: string[];
    years: string[];
    stage: NpvAnalyticsStage[];
    detalization: NpvAnalyticsDetalization;
    onlyIntroductory: boolean;
    onlyFinished: boolean;
};

export type NpvCommonProps = {
    filters: NpvAnalyticsFilters;
    setFilters: React.Dispatch<React.SetStateAction<NpvAnalyticsFilters>>;
};

export enum NpvDataDisplayFormat {
    BY_WELL = 'BY_WELL',
    BY_MONTH = 'BY_MONTH',
}
