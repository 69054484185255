import {IconButton} from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

type FullScreenButtonProps = {
    isFullScreen: boolean;
    setIsFullScreen: (isFullScreen: boolean) => void;
};

export const FullScreenButton = ({isFullScreen, setIsFullScreen}: FullScreenButtonProps) => {
    return (
        <IconButton
            onClick={() => {
                setIsFullScreen(!isFullScreen);
            }}
        >
            {isFullScreen ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
        </IconButton>
    );
};
