import {Stack, useTheme} from '@mui/material';
import {ChartCode, ChartComponentProps, ChartFilter, ChartItem} from '@ui/Chart';
import {useEffect, useMemo, useRef, useState} from 'react';
import {NewChart} from '@ui/NewChart/NewChart';
import {mergeObjects} from '@ui/NewChart/chartConfigurations/utils';
import {getChartConfiguration} from '@ui/NewChart/chartConfigurations/getChartConfiguration';
import {EChartsInstance} from 'echarts-for-react';
import {useGgdChartOption} from './useGgdChartOption';
import {useChart} from '@ui/Chart/useChart';
import {GgdChartHeader} from './GgdChartHeader';
import clsx from 'clsx';
import styles from '@ui/ChartWrapper/ChartWrapper.module.scss';
import {ChartLineData} from '@ui/NewChart/types';

type GgdChartProps = Partial<ChartComponentProps> & {
    chartData: ChartItem;
    config?: {
        withIsOnlyDrilling?: boolean;
        withOpacity?: boolean;
    };
    backgroundColor?: string;
    getCustomLineColor?: (seriesData: ChartLineData[], line: ChartLineData) => string;
};

export const GgdChart = ({
    chartData,
    config: componentConfig,
    getCustomLineColor,
    wellId,
    forceReload,
    backgroundColor,
}: GgdChartProps) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const theme = useTheme();

    const [outerFilters, setOuterFilters] = useState<Record<string, boolean>>({
        isOnlyDrilling: false,
        isShowLKS: false,
    });

    const formattedFilters = useMemo(() => {
        const flags: string[] = [];
        if (outerFilters.isOnlyDrilling) {
            flags.push('isOnlyDrilling');
        }
        if (outerFilters.isShowLKS) {
            flags.push('isShowLKS');
        }
        if (flags.length === 0) {
            return {} as ChartFilter;
        }
        return {flags};
    }, [outerFilters]);

    const [ggdChartData, loading] = useChart({
        code: chartData.code,
        filters: formattedFilters,
        wellId,
        forceReload,
    });

    const {customOption} = useGgdChartOption({
        data: ggdChartData,
        outerFilters,
        componentConfig,
        getCustomLineColor,
        backgroundColor,
    });

    const chartRef = useRef<EChartsInstance>();

    const divRef = useRef<HTMLDivElement>(null);
    // Функция, которая будет вызываться при клике вне компонента ДЛЯ закрытия тутлипов
    const handleClickOutside = (event: any) => {
        // Проверяем, если клик был вне компонента
        if (divRef.current && !divRef.current.contains(event.target)) {
            const chartInstance = chartRef.current.getEchartsInstance();
            chartInstance.dispatchAction({
                type: 'hideTip',
            });
        }
    };

    useEffect(() => {
        // Добавляем обработчик клика на документ
        document.addEventListener('mousedown', handleClickOutside);

        // Убираем обработчик клика при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const option = mergeObjects(
        customOption,
        getChartConfiguration({code: ChartCode.GGD_FACT, theme}),
    );

    const isEmpty = ggdChartData?.length === 0;

    return (
        <Stack
            height='100%'
            className={clsx({
                [styles.fullscreen]: isFullScreen,
            })}
            style={{backgroundColor}}
        >
            <GgdChartHeader
                outerFilters={outerFilters}
                setOuterFilters={setOuterFilters}
                isEmpty={isEmpty}
                componentConfig={componentConfig}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                chartRef={chartRef}
                title={chartData.title}
            />
            <Stack
                gap={1}
                height='90%'
                ref={divRef}
            >
                <NewChart
                    option={option}
                    ref={chartRef}
                    loading={loading}
                    isEmpty={isEmpty}
                />
            </Stack>
        </Stack>
    );
};
