import 'echarts-gl';

export const trajectory3DConfiguration = () => {
    return {
        tooltip: {},
        xAxis3D: {
            type: 'value',
            nameTextStyle: {
                color: 'var(--mui-palette-text-secondary)',
            },
        },
        yAxis3D: {
            type: 'value',
            nameTextStyle: {
                color: 'var(--mui-palette-text-secondary)',
            },
        },
        zAxis3D: {
            type: 'value',
            nameTextStyle: {
                color: 'var(--mui-palette-text-secondary)',
            },
        },
        grid3D: {
            viewControl: {
                projection: 'orthographic',
            },
        },
        series: [],
    };
};
