import {useColorScheme} from '@mui/material';
import {ChartLineData} from '@ui/NewChart/types';
import {EChartOption} from 'echarts';
import {useCallback, useMemo} from 'react';

const getLineColor = (type: string, colorScheme?: string) => {
    if (type.includes('Плановый забой')) return '#448AFF';
    if (type.includes('Фактический забой')) return '#4CAF50';
    if (type.includes('Отклонение от плана')) return '#7C4DFF';
    if (type.includes('ЛКС'))
        return colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(0, 0, 0, 0.60)';
    return '#7C4DFF';
};

type useGgdChartOptionProps = {
    data: ChartLineData[];
    outerFilters: Record<string, boolean>;
    componentConfig?: Record<string, boolean | undefined>;
    getCustomLineColor?: (seriesData: ChartLineData[], line: ChartLineData) => string;
    backgroundColor?: string;
};

export const useGgdChartOption = ({
    data,
    outerFilters,
    componentConfig,
    getCustomLineColor,
    backgroundColor,
}: useGgdChartOptionProps) => {
    const {colorScheme} = useColorScheme();

    const getSeries = useCallback(
        (seriesData: ChartLineData[]) => {
            return seriesData.map((line) => {
                const lineStyle: any = {};

                // Условие для линии с title.includes('ЛКС')
                if (line.title.includes('ЛКС')) {
                    lineStyle.type = 'dashed';
                    lineStyle.shadowColor = colorScheme === 'light' ? '' : 'rgba(255,255,255, 0.5)';
                    lineStyle.shadowBlur = 8;
                    lineStyle.width = 2;
                }

                // Условие для прозрачности линии
                const opacity =
                    componentConfig?.withOpacity &&
                    outerFilters.isShowLKS &&
                    !line.title.includes('ЛКС')
                        ? 0.45
                        : 1;

                const color = getCustomLineColor
                    ? getCustomLineColor(seriesData, line)
                    : getLineColor(line.title, colorScheme);

                return {
                    name: line.title,
                    type: 'line',
                    data: line.values.map((point) => [point.x, point.y]), // Преобразуем [{x, y}] в [[x, y]]
                    itemStyle: {
                        color: color,
                    },
                    lineStyle: {
                        color: color,
                        ...lineStyle,
                        opacity,
                    },
                    symbol: 'none', // Убираем точки на графике
                };
            });
        },
        [colorScheme, componentConfig?.withOpacity, getCustomLineColor, outerFilters.isShowLKS],
    );

    const customOption = useMemo(() => {
        return {
            series: getSeries(data || []),
            backgroundColor: backgroundColor,
        } as EChartOption;
    }, [getSeries, data, backgroundColor]);
    return {customOption};
};
