import {EChartOption} from 'echarts';

const color = 'rgba(0, 0, 0, 0.6)';

export const customChartsLightTheme: EChartOption = {
    backgroundColor: '#fff',
    textStyle: {
        color,
    },
    tooltip: {
        textStyle: {color},
    },
    legend: {
        textStyle: {
            color,
        },
        pageTextStyle: {
            color: 'rgba(0, 0, 0, 0.54)',
        },
        pageIconColor: 'rgba(0, 0, 0, 0.54)',
        pageIconInactiveColor: 'rgba(0, 0, 0, 0.12)',
    },
    // @ts-ignore
    valueAxis: {
        splitLine: {
            lineStyle: {
                color: 'rgba(0, 0, 0, 0.12)',
            },
        },
    },
    axisLabel: {
        color,
    },
};
