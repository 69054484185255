import {Theme} from '@mui/material';
import {EChartOption} from 'echarts';

export const costByDayConfiguration = (theme: Theme): EChartOption => {
    return {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
        },
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: [0],
            },
        ],
    };
};
