import dayjs from 'dayjs';
import {DT_FORMATS} from 'utils';

export const downloadURI = (uri: string, name: string) => {
    const currentDate = dayjs().format(DT_FORMATS.default);
    // Создаем временный тег <a>, чтобы инициировать загрузку
    const link = document.createElement('a');
    link.href = uri;
    link.download = `${name}-${currentDate}.png`; // Имя файла для сохранения
    link.style.display = 'none';

    // Добавляем ссылку в DOM, кликаем по ней, а затем удаляем
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
