import {Stack, Typography, useColorScheme} from '@mui/material';
import {EChartOption} from 'echarts';
import ReactECharts, {EChartsInstance} from 'echarts-for-react';
import {customChartsDarkTheme} from './customDarkTheme';
import * as echarts from 'echarts';
import {FlexLoader} from '@ui/Loader/FlexLoader';
import {customChartsLightTheme} from './customLightTheme';
import {ForwardedRef, forwardRef} from 'react';

// Регистрируем тему в echarts
echarts.registerTheme('dark', customChartsDarkTheme);
echarts.registerTheme('light', customChartsLightTheme);

type NewChartProps = {
    option: EChartOption;
    loading?: boolean;
    emptyTitle?: string;
    isEmpty?: boolean;
    onEvents?: Record<string, Function>;
};

// Это компонент чарта, он не имеет логики, ТОЛЬКО отображение графика.
// Фильтры, названия и все дополнительные действия мы совершаем ЗА переделами этого компонента
export const NewChart = forwardRef(
    (
        {option, isEmpty = false, loading, emptyTitle = 'Нет данных', onEvents}: NewChartProps,
        ref: ForwardedRef<EChartsInstance>,
    ) => {
        const {colorScheme} = useColorScheme();

        if (loading) {
            return <FlexLoader />;
        }

        if (isEmpty) {
            return (
                <Stack>
                    <Typography
                        margin='30px auto 0'
                        component='div'
                        variant='body1'
                    >
                        {emptyTitle}
                    </Typography>
                </Stack>
            );
        }

        return (
            <ReactECharts
                ref={ref}
                option={option}
                theme={colorScheme}
                style={{width: '100%', height: '100%'}}
                lazyUpdate={true}
                onEvents={onEvents}
            />
        );
    },
);
