import {IconButton} from '@mui/material';
import {ReactComponent as ZoomOutOutlinedIcon} from 'assets/icons/zoomOut.svg';

type ResetButtonProps = {
    onClick: () => void;
};

export const ResetButton = ({onClick}: ResetButtonProps) => {
    return (
        <IconButton onClick={onClick}>
            <ZoomOutOutlinedIcon />
        </IconButton>
    );
};
