import {EChartOption} from 'echarts';

const defaultTextStyles = {
    fontFamily: 'Roboto',
    fontSize: 11,
};

// Общая конфига для всех графиков
export const getCommonChartsConfig = (): EChartOption => {
    return {
        color: [
            '#4487F7',
            '#00A495',
            '#FF8A65',
            '#CC8BF8',
            '#7C6CF7',
            '#7FC44F',
            '#00C0D8',
            '#E18504',
            '#5796FE',
            '#EF8DCA',
            '#4183A1',
            '#FFF176',
            '#61C77E',
            '#B1BE3E',
            '#B786F5',
        ],
        textStyle: {
            ...defaultTextStyles,
        },
        tooltip: {
            trigger: 'axis',
            borderWidth: 0,
            // @ts-ignore
            className: 'chart-tooltip',
            textStyle: {...defaultTextStyles, fontSize: 12},
        },
        legend: {
            type: 'scroll',
            icon: 'circle',
            pageButtonItemGap: 0,
            textStyle: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                // @ts-ignore
                overflow: 'truncate',
            },
            pageTextStyle: {
                ...defaultTextStyles,
            },
            pageIconSize: 7,
        },
        grid: {
            left: 40,
            top: 50,
            right: '4%',
            bottom: 30,
        },
        valueAxis: {
            splitLine: {
                lineStyle: {
                    width: 0.5,
                },
            },
        },
        axisLabel: {
            ...defaultTextStyles,
        },
    };
};
