import {FormControlLabel, Stack, Switch, Typography} from '@mui/material';
import {FullScreenButton} from '@ui/ChartWrapper/components/FullScreenButton';
import {ResetButton} from '@ui/ChartWrapper/components/ResetButton';
import {SaveAsImageButton} from '@ui/ChartWrapper/components/SaveAsImageButton';
import {downloadURI} from '@ui/ChartWrapper/utils';
import {EChartsInstance} from 'echarts-for-react';

type GgdChartHeaderProps = {
    outerFilters: Record<string, boolean>;
    setOuterFilters: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    isEmpty: boolean;
    componentConfig?: Record<string, boolean>;
    isFullScreen: boolean;
    setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
    chartRef: React.RefObject<EChartsInstance>;
    title: string;
};

export const GgdChartHeader = ({
    isFullScreen,
    setIsFullScreen,
    outerFilters,
    setOuterFilters,
    isEmpty,
    componentConfig,
    chartRef,
    title,
}: GgdChartHeaderProps) => {
    // Функция для сброса зума
    const handleRestoreChart = () => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();
            chartInstance.dispatchAction({
                type: 'dataZoom',
                start: 0,
                end: 100,
            });
        }
    };

    const handleDownloadChart = () => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();
            const dataURL = chartInstance.getDataURL({
                type: 'png',
                pixelRatio: 5,
            });
            downloadURI(dataURL, title);
        }
    };

    return (
        <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
        >
            <Typography
                variant='h3'
                sx={{mr: 'auto'}}
            >
                ГГД
            </Typography>
            <FormControlLabel
                value={outerFilters.isShowLKS}
                control={
                    <Switch
                        checked={outerFilters.isShowLKS}
                        onChange={() =>
                            setOuterFilters({
                                ...outerFilters,
                                isShowLKS: !outerFilters.isShowLKS,
                            })
                        }
                        inputProps={{'aria-label': 'controlled'}}
                    />
                }
                label={<Typography variant='subtitle2'>Показывать ЛКС</Typography>}
                labelPlacement='end'
            />
            {componentConfig?.withIsOnlyDrilling && (
                <FormControlLabel
                    value={outerFilters.isOnlyDrilling}
                    control={
                        <Switch
                            checked={outerFilters.isOnlyDrilling}
                            onChange={() =>
                                setOuterFilters({
                                    ...outerFilters,
                                    isOnlyDrilling: !outerFilters.isOnlyDrilling,
                                })
                            }
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    }
                    label={<Typography variant='subtitle2'>Только бурение</Typography>}
                    labelPlacement='end'
                />
            )}
            {!isEmpty && <ResetButton onClick={handleRestoreChart} />}
            {!isEmpty && <SaveAsImageButton onClick={handleDownloadChart} />}

            <FullScreenButton
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
            />
        </Stack>
    );
};
