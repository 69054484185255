import {EChartOption} from 'echarts';

export const ggdFactConfiguration = (): EChartOption => {
    return {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'value',
            nameLocation: 'start', // Подпись оси X сверху
            position: 'top', // Размещаем ось X сверху
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: 'value',
            inverse: true, // Меняем направление оси Y,
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: [0],
            },
            {
                type: 'inside',
                yAxisIndex: [0],
            },
        ],
        legend: {
            left: 0,
        },
    };
};
