import {EChartOption} from 'echarts';

export const trajectoryVerticalSliceConfiuration = (): EChartOption => {
    return {
        xAxis: {
            position: 'top',
        },
        yAxis: {
            inverse: true,
        },
        tooltip: {
            position: (point: any) => [point[0] + 10, point[1] + 10],
        },
        dataZoom: [
            {
                type: 'inside',
                xAxisIndex: [0],
            },
            {
                type: 'inside',
                yAxisIndex: [0],
            },
        ],
    };
};
